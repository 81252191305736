<template>
  <div class="content">
    <l-toggle>
      <dynamic-form :formItems="formItems" :model.sync="model"></dynamic-form>
      <search-reset :loading="loading" :model.sync="model" @search="
          _clearPagination();
          getList();
        "></search-reset>
    </l-toggle>
    <dynamic-table
      ref="dynamic-table"
      :loading="loading"
      :columns="columns"
      :tableData="tableData"
      :pagination.sync="pagination"
      :selections.sync="selections"
      :tools="tools"
      @update="getList"
    >
    </dynamic-table>
    <add-company-account ref="add-company-account" @refreshTable="refreshTable">
    </add-company-account>
  </div>
</template>

<script>
import LToggle from '../../components/LToggle.vue';
import DynamicForm from '../../components/DynamicForm.vue';
import SearchReset from '../../components/SearchReset.vue';
import DynamicTable from '../../components/DynamicTable.vue';
import AddCompanyAccount from './CompanyAccount/AddCompanyAccount.vue';
import baseMixin from '../../mixins/base-mixin';
import api from '../../api/CompanyAccountApi';

export default {
  name: '',
  components: {
    LToggle,
    DynamicForm,
    SearchReset,
    DynamicTable,
    AddCompanyAccount,
  },
  mixins: [baseMixin],
  data() {
    return {
      model: {},
      formItems: [
        {
          label: '账号属性',
          type: 'select',
          name: 'accountAttribute',
          placeholder: '请选择账号属性',
          options: this.filterDictionary('0126'),
        },
        {
          label: '账号用途',
          type: 'select',
          name: 'accountUse',
          placeholder: '请选择账号用途',
          options: this.filterDictionary('0128'),
        },
        {
          label: '账号种类',
          type: 'select',
          name: 'accountType',
          placeholder: '请选择账号种类',
          options: this.filterDictionary('0127'),
        },
        {
          label: '账号名称',
          type: 'text',
          name: 'accountName',
          placeholder: '请输入账号名称',
        },
        {
          label: '账号',
          type: 'text',
          name: 'accountNumber',
          placeholder: '请输入账号',
        },
      ],
      columns: [
        {
          type: 'selection',
          fixed: 'left',
        },
        {
          type: 'index',
          label: '序号',
        },
        {
          label: '账号属性',
          prop: 'accountAttribute',
          dictTranslate: true,
          dictCode: '0126',
        },
        {
          label: '账号用途',
          prop: 'accountUse',
          dictTranslate: true,
          dictCode: '0128',
        },
        {
          label: '账号种类',
          prop: 'accountType',
          dictTranslate: true,
          dictCode: '0127',
        },
        {
          label: '账号名称',
          prop: 'accountName',
        },
        {
          label: '账号',
          prop: 'accountNumber',
        },
        {
          label: '操作',
          prop: '_action',
          fixed: 'right',
          actions: [
            {
              title: '编辑',
              type: 'text',
              icon: 'el-icon-edit',
              event: this.modify,
              id: 'edit',
            },
            {
              title: '删除',
              type: 'text',
              icon: 'el-icon-delete',
              event: this.delete,
            },
          ],
        },
      ],
      tableData: [],
      loading: false,
      pagination: {
        total: 0,
        pageSize: 30,
        currentPage: 1,
      },
      selections: [],
      tools: [
        {
          title: '添加账号',
          type: 'primary',
          icon: 'el-icon-plus',
          event: this.add,
        },
        {
          title: '删除选择',
          type: 'danger',
          icon: 'el-icon-delete',
          event: this._deleteSelected,
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {
    getList() {
      this.loading = true;
      const promise = new Promise((resolve, reject) => {
        const { model, pagination } = this;
        const { currentPage, pageSize } = pagination;
        const ext = {
          pageNum: currentPage,
          pageSize,
          ...model,
        };
        api
          .getCompanyAccountPageList(ext)
          .then(res => {
            const { datas, totalRecords } = res.result;
            this.tableData = datas;
            this.pagination.total = totalRecords;
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {
            this.loading = false;
          });
      });
      return promise;
    },
    refreshTable() {
      this.pagination.currentPage = 1;
      this.getList();
    },
    deleteCompanyAccount(ids) {
      const promise = new Promise((resolve, reject) => {
        const ext = {
          idList: ids,
        };
        api
          .deleteCompanyAccount(ext)
          .then(res => {
            this.getList();
            resolve(res);
          })
          .catch(e => {
            reject(e);
          })
          .finally(() => {});
      });
      return promise;
    },
    delete(row) {
      this.$confirm('确定删除该条数据吗?', '提示', {
        type: 'warning',
        showClose: false,
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.deleteCompanyAccount([row.id]).finally(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success('操作成功');
        })
        .catch(() => {});
    },
    add() {
      this.$refs['add-company-account'].open();
    },
    modify(row) {
      this.$refs['add-company-account'].modifyOpen(row);
    },
  },
};
</script>

<style lang="scss"></style>
