<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form ref="elform" :model="formData" label-width="150px" size="small" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="请选择账号属性">
              <el-select v-model="formData.accountAttribute" placeholder="请选择账号属性" clearable>
                <el-option
                  v-for="(option, optionIndex) in this.filterDictionary('0126')"
                  :key="`color-${optionIndex}`"
                  :label="option.name"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请选择账号用途">
              <el-select v-model="formData.accountUse" placeholder="请选择账号用途" clearable>
                <el-option
                  v-for="(option, optionIndex) in this.filterDictionary('0128')"
                  :key="`color-${optionIndex}`"
                  :label="option.name"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请选择账号种类">
              <el-select v-model="formData.accountType" placeholder="请选择账号种类" clearable>
                <el-option
                  v-for="(option, optionIndex) in this.filterDictionary('0127')"
                  :key="`color-${optionIndex}`"
                  :label="option.name"
                  :value="option.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请输入账号名称" prop="accountName">
              <el-input v-model="formData.accountName" placeholder="请输入账号名称"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请输入账号" prop="accountNumber">
              <el-input v-model="formData.accountNumber" placeholder="请输入账号"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="submitForm" :loading="loading"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utils, { numberCtrol } from '@/utils/utils';
import api from '@/api/CompanyAccountApi';

export default {
  name: 'AddCompanyAccount',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '添加账号',
      loading: false,
      formData: {
        accountAttribute: '',
        accountName: '',
        accountNumber: '',
        accountType: '',
        accountUse: '',
        id: '',
      },
      rules: {
        accountAttribute: [
          {
            required: true,
            validator: utils.scaleVerify,
            trigger: 'change',
          },
        ],
        accountUse: [
          {
            required: true,
            validator: utils.scaleVerify,
            trigger: 'change',
          },
        ],
        accountType: [
          {
            required: true,
            validator: utils.scaleVerify,
            trigger: 'change',
          },
        ],
        accountName: [
          {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
        ],
        accountNumber: [
          {
            required: true,
            message: '请输入',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {},
  methods: {
    open() {
      this.dialogVisible = true;
      this.dialogTitle = '添加账号';
      this.formData = {};
    },
    modifyOpen(row) {
      this.dialogVisible = true;
      this.dialogTitle = '编辑账号';
      this.formData.accountAttribute = row.accountAttribute;
      this.formData.accountUse = row.accountUse;
      this.formData.accountType = row.accountType;
      this.formData.accountName = row.accountName;
      this.formData.accountNumber = row.accountNumber;
      this.formData.id = row.id;
    },
    close() {
      this.dialogVisible = false;
    },
    numberCtrol,
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            accountAttribute,
            accountUse,
            accountType,
            accountName,
            accountNumber,
            id,
          } = this.formData;
          if (this.dialogTitle === '添加账号') {
            api
              .addCompanyAccount({
                accountAttribute,
                accountUse,
                accountType,
                accountName,
                accountNumber,
              })
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api
              .modifyCompanyAccount({
                accountAttribute,
                accountUse,
                accountType,
                accountName,
                accountNumber,
                id,
              })
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped></style>
